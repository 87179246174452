import React from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import Cards from "../components/cards/cards"
import Video from "../components/video/video"
import bgVideo from '../components/video/01_THS_Animatic_Final_Live-IPTV-Compressed.mp4'
import videoPoster from "../components/video/video-poster-iptv.jpg"

const LiveIPTV = () => (
  <Layout>
    <SEO title="IPTV" />
    <div className="cardsContainer cardsContainer--live-iptv">
        <Cards postCount={5} hero={true} />
        <Video src={bgVideo} poster={videoPoster} />
    </div>
  </Layout>
)

export default LiveIPTV
